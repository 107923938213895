import React from "react";
import { useEffect } from "react";
import AuthComponent from "../components/AuthComponent";
import { useBoundStore } from "../store";
import { useNavigate } from "react-router-dom";
import { useSetState } from "@mantine/hooks";
import { MultiFactorError, MultiFactorResolver } from "firebase/auth";
import { resolveMultifactorLogin } from "../services/mfa";

type StateValues = {
    loading: boolean;
    error: boolean;
    showVerificationInput: boolean;
    verificationCodeId: string | null;
    resolver: MultiFactorResolver | null;
};

const AuthLayout = () => {
    const { login, isAuthenticated, multifactorEnabled } = useBoundStore();
    const navigate = useNavigate();
    const [state, setState] = useSetState<StateValues>({
        loading: false,
        error: false,
        showVerificationInput: false,
        verificationCodeId: null,
        resolver: null,
    });

    const { loginWithMultifactorResolver } = useBoundStore();

    const handleResolverError = async (e: MultiFactorError) => {
        // lets handle multifactor resolution
        const { resolver, verificationCodeId } = await resolveMultifactorLogin(
            e as MultiFactorError
        );
        if (!resolver || !verificationCodeId) {
            return;
        }
        setState({
            ...state,
            showVerificationInput: true,
            resolver,
            verificationCodeId,
        });
    };

    const handleCodeVerification = async (code: string) => {
        try {
            if (!state.resolver || !state.verificationCodeId) {
                return;
            }
            setState({ loading: true });
            await loginWithMultifactorResolver(
                state.resolver,
                state.verificationCodeId,
                code
            );
            navigate("/dashboard");
        } catch (e) {
            console.error(e);
            setState({ error: true });
        } finally {
            setState({ loading: false });
        }
    };

    const handleSignIn = async (
        email: string,
        password: string
    ): Promise<void> => {
        try {
            setState({ loading: true });
            await login(email, password);
            if (!multifactorEnabled) {
                navigate("/2fa-enrollment");
            }
        } catch (e) {
            console.error((e as any).code);
            if (
                (e as MultiFactorError).code ===
                "auth/multi-factor-auth-required"
            ) {
                handleResolverError(e as MultiFactorError);
                return;
            }
            setState({ error: true });
            console.error(e);
        } finally {
            setState({ loading: false });
        }
    };

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }
        navigate("/dashboard");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return (
        <AuthComponent
            onSubmit={handleSignIn}
            error={state.error}
            loading={state.loading}
            showVerificationCodeInput={state.showVerificationInput}
            onVerify={handleCodeVerification}
        />
    );
};

export default AuthLayout;
